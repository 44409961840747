import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

import App from './App';

// firebase init
const app = initializeApp({
  apiKey: 'AIzaSyCOE1gn31JWc4y_4DUsRu_-be-JeSxnvWA',
  authDomain: 'assetpro-6f32f.firebaseapp.com',
  projectId: 'assetpro-6f32f',
  storageBucket: 'assetpro-6f32f.appspot.com',
  messagingSenderId: '139012269571',
  appId: '1:139012269571:web:050ecfbe0cd411653d9036',
  measurementId: 'G-N4EKNB9BL4'
});
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdrbNwoAAAAABMw8vVkABVPrq3rQJVnbL-o1hi2'),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
