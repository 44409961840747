import { Card, Image, Tab, TabList, Text, Tooltip } from '@fluentui/react-components';

import Grid from '@/component/style-component/Grid';
import LogoSvg from '@/assets/logo.svg';

const Side = () => {
  return (
    <Card>
      <Grid direction="column" className="py-0.5 px-5" gap={32}>
        <Grid alignItems="center">
          <Image src={LogoSvg} width={30} />
          <Text size={400} className="ml-2">
            AssetMe
          </Text>
        </Grid>
        <TabList selectedValue="tab1" vertical>
          <Tab value="tab1">Tokens</Tab>
          <Tab value="tab2">
            <Tooltip content="NFT assets. Coming soon." relationship="label">
              <Text>NFTs</Text>
            </Tooltip>
          </Tab>
          <Tab value="tab3">
            <Tooltip content="Assets in protocols. Coming soon." relationship="label">
              <Text>Protocols</Text>
            </Tooltip>
          </Tab>
        </TabList>
      </Grid>
    </Card>
  );
};

export default Side;
