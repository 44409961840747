import React from 'react';

import AssetList from '@/component/AssetList';

const Overview = () => {
  return (
    <div className="p-8">
      <AssetList />
    </div>
  );
};

export default Overview;
