import { themeToTokensObject, webDarkTheme } from '@fluentui/react-components';

const darkTheme = {
  ...webDarkTheme,
  fontSizeBase300: '13px',
  colorCompoundBrandStroke: 'rgba(255, 255, 255, 0.9)',
  colorCompoundBrandStrokeHover: 'rgba(255, 255, 255, 0.9)',
  colorCompoundBrandStrokePressed: 'rgba(255, 255, 255, 0.9)'
};

export const themeTokens = themeToTokensObject(darkTheme);

export const theme = darkTheme;
