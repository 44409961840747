import BigNumber from 'bignumber.js';
import { sortBy } from 'lodash';
import { convertRate, RateInterval, xirr as nodeXirr } from 'node-irr';
import numbro from 'numbro';

const isNumeric = (obj: any) => {
  return !Number.isNaN(parseFloat(obj)) && Number.isFinite(Number(obj));
};
const preFormatNumber = (value: number | BigNumber) => {
  if (BigNumber.isBigNumber(value)) {
    const stringValue = value.toString(10);
    const [int, dec] = stringValue.split('.');
    if (dec && dec.length > 15) {
      value = Number(`${int}.${dec.slice(0, 15)}`);
    } else {
      value = Number(stringValue);
    }
  }
  return value && isNumeric(value) ? value : 0;
};

/* Format */
export const formatCurrency = (value: number | BigNumber, forceSign = false) => {
  const number = preFormatNumber(value);

  // 因为使用 2 位小数，凡是不能四舍五入进 2 位小数的，都会输出 -$
  if (Math.abs(number) < 0.005 && forceSign) {
    return '$0.00';
  }

  return numbro(number)
    .formatCurrency({
      thousandSeparated: true,
      mantissa: 2,
      average: true,
      forceSign,
      roundingFunction: Math.round
    })
    .toUpperCase();
};

export const formatQuantity = (value: number | BigNumber, customFormat = {}) => {
  if (value === 0 || value === Infinity || value === -Infinity) {
    return value;
  }
  const number = preFormatNumber(value);

  const format = {
    thousandSeparated: true,
    mantissa: 4,
    average: true,
    roundingFunction: Math.round,
    ...customFormat
  };
  return numbro(number).format(format).toUpperCase();
};

export const formatPercent = (value: number | BigNumber, forceSign = false) => {
  const number = preFormatNumber(value);

  if (Math.abs(number) < 0.0001) {
    return '0.00%';
  }

  return numbro(number).format({
    output: 'percent',
    mantissa: 2,
    average: true,
    thousandSeparated: true,
    forceSign,
    roundingFunction: Math.round
  });
};

/* Quantity to balance */
export const toBalance = <T extends string | number | BigNumber>(
  quantity: string,
  decimals: number,
  dataType?: 'string' | 'number'
): T => {
  const balance = BigNumber(quantity).shiftedBy(-decimals);
  if (dataType === 'string') {
    return balance.toString(10) as T;
  }
  if (dataType === 'number') {
    return balance.toNumber() as T;
  }
  return balance as T;
};

/* Truncate Text Mid */
export const truncateTextMid = (text: string, start = 6, end = 4) =>
  text.length > start + end ? text.replace(text.substring(start, text.length - end), '...') : text;

// truncate text end
export const truncateTextEnd = (text: string, length = 6) =>
  text.length > length ? `${text.substring(0, length)}...` : text;

// truncate text start
export const truncateTextStart = (text: string, length = 6) =>
  text.length > length ? `...${text.substring(text.length - length)}` : text;

// String first letter to uppercase
export const firstLetterToUpperCase = (str = '') => str.replace(/^\S/, s => s.toUpperCase());

export const calcXirr = (cashFlows: { date: string; value: string }[], needTransform = false) => {
  const sortedCashFlow = sortBy(cashFlows, 'date')
    .map(({ date, value }, index) => {
      if (needTransform && index < cashFlows.length - 1) {
        return { date, amount: -Number(value) };
      }
      return { date, amount: Number(value) };
    })
    .filter(({ amount }) => amount !== 0);

  const { days, rate } = nodeXirr(sortedCashFlow);
  return {
    days,
    rate: convertRate(rate, RateInterval.Year)
  };
};
