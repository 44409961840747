import React from 'react';
import { Text, TextProps } from '@fluentui/react-components';

import { theme } from '@/theme';

type Variant = 'primary' | 'secondary' | 'tertiary' | 'success' | 'error' | 'warning';

const variant: {
  [key in Variant]: string;
} = {
  primary: theme.colorNeutralForeground1,
  secondary: theme.colorNeutralForeground3,
  tertiary: theme.colorNeutralForeground4,
  success: '',
  error: theme.colorStatusDangerForeground1,
  warning: ''
};

type Props = {
  children: React.ReactNode;
  type?: Variant;
} & TextProps;

const ColorText = ({ type = 'primary', children, ...props }: Props) => {
  return (
    <Text {...props} style={{ color: variant[type] }}>
      {children}
    </Text>
  );
};

export default ColorText;
