import { useEffect, useState } from 'react';
import { keyBy } from 'lodash';

import { requestFromFF } from '@/utils/request';
import { FUNCTION } from '@/utils/const';
import { LatestCoinInfo } from '@/types/interface';

// coin 实时信息：价格、PnL
const useLatestCoinInfo = (address: string | null) => {
  const [latestCoinInfo, setLatestCoinInfo] = useState<{
    [key: string]: LatestCoinInfo;
  }>({});
  useEffect(() => {
    if (!address) {
      return;
    }
    requestFromFF<LatestCoinInfo[]>(FUNCTION.GET_LATEST_COIN_INFO, address).then(res => {
      setLatestCoinInfo(keyBy(res, 'contract_address'));
    });
  }, [address]);

  return latestCoinInfo;
};

export default useLatestCoinInfo;
