import React from 'react';
import { Text, TextProps } from '@fluentui/react-components';

const red = '#f63d3d';
const green = '#00c087';

const MarketText = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & TextProps) => {
  return (
    <Text
      {...props}
      style={
        // eslint-disable-next-line no-nested-ternary
        typeof children === 'string'
          ? children.startsWith('-')
            ? { color: red }
            : { color: green }
          : {}
      }
    >
      {children}
    </Text>
  );
};

export default MarketText;
