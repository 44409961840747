import React from 'react';
import { Grid, GridProps, ScaleProps } from '@geist-ui/core';

const GapSize = {
  row: {
    2: 'space-x-0.5',
    4: 'space-x-1',
    8: 'space-x-2',
    16: 'space-x-4',
    32: 'space-x-8',
    48: 'space-x-12'
  },
  column: {
    2: 'space-y-0.5',
    4: 'space-y-1',
    8: 'space-y-2',
    16: 'space-y-4',
    32: 'space-y-8',
    48: 'space-y-12'
  }
};

type Props = {
  children: React.ReactNode;
  gap?: keyof typeof GapSize['row'];
  dangerouslySetInnerHTML?: {
    __html: string;
  };
} & GridProps &
  ScaleProps;

const StyledGrid = ({ children, gap, className = '', ...props }: Props) => {
  return (
    <Grid
      className={`flex ${
        gap ? GapSize[props.direction?.includes('column') ? 'column' : 'row'][gap] : ''
      } ${className}`}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default StyledGrid;
