import {
  Button,
  Input,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Text
} from '@fluentui/react-components';
import { Wallet20Regular } from '@fluentui/react-icons';
import React, { useContext, useState } from 'react';
import { isAddress } from 'ethers';

import { AddressContext } from './Provider/AddressProvider';
import ColorText from './style-component/ColorText';

import Grid from '@/component/style-component/Grid';
import { truncateTextMid } from '@/utils';

const AddressSet = () => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const { address, setAddress } = useContext(AddressContext);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setErrMsg('');
  };

  const handleConfirm = () => {
    if (isAddress(inputValue)) {
      setAddress(inputValue);
      setOpen(false);
    } else {
      setErrMsg('Invalid Address');
    }
  };

  return (
    <Popover
      withArrow
      trapFocus
      open={open}
      onOpenChange={(e, data) => setOpen(data.open || false)}
    >
      <PopoverTrigger disableButtonEnhancement>
        {address ? (
          <div>
            <Grid alignItems="center" gap={2}>
              <Wallet20Regular />
              <Text>{truncateTextMid(address || '')}</Text>
            </Grid>
          </div>
        ) : (
          <Button>Set Address</Button>
        )}
      </PopoverTrigger>

      <PopoverSurface>
        <Grid direction="column" gap={8}>
          <Grid className="text-right" gap={4} direction="column">
            <Input
              onChange={handleInputChange}
              value={inputValue}
              placeholder="Input Ethereum Address"
            />
            {!!errMsg && (
              <ColorText className="text-right" size={200} type="error">
                {errMsg}
              </ColorText>
            )}
          </Grid>
          <div className="text-right">
            <Button appearance="outline" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </Grid>
      </PopoverSurface>
    </Popover>
  );
};

export default AddressSet;
