import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';

import { FUNCTION } from './const';

import { generalReturn } from '@/types/interface';
/**
 * 请求 Firebase Functions
 * @param funcName Firebase function name
 * @param param Parameters
 * @returns Promise<T>
 */
export const requestFromFF = async <T>(funcName: FUNCTION, param?: string | object): Promise<T> => {
  const functions = getFunctions();
  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }
  const fn = httpsCallable<string | object, generalReturn<T>>(functions, funcName);
  return fn(param)
    .then(({ data: { status, results } }: { data: generalReturn<T> }) => {
      if (status === 'error') {
        // showErrorAlert(null, funcName);
      }
      return results;
    })
    .catch(error => {
      // showErrorAlert(error, funcName);
      throw error;
    });
};
