import Grid from './style-component/Grid';
import AddressSet from './AddressSet';

const Header = () => {
  return (
    <Grid style={{ padding: '0 32px' }} height="56px" alignItems="center" justify="space-between">
      {/* <Grid alignItems="center">
        <Image src={LogoSvg} width={30} />
        <Text size={400} className="ml-2">
          AssetMe
        </Text>
      </Grid> */}
      <AddressSet />
    </Grid>
  );
};

export default Header;
