import { useEffect, useState } from 'react';
import { chain } from 'lodash';
import BigNumber from 'bignumber.js';

import useLatestCoinInfo from './useLatestCoinInfo';

import { requestFromFF } from '@/utils/request';
import { FUNCTION } from '@/utils/const';
import { AssetCost } from '@/types/interface';
import { toBalance } from '@/utils';

export type FusedAssetData = {
  contract_address: string;
  price: string;
  symbol: string;
  balance: BigNumber;
  value: BigNumber;
  total_cost: string;
  start_timestamp: string;
};

const fuseData = (data: AssetCost[], latestCoinInfo: ReturnType<typeof useLatestCoinInfo>) => {
  return Object.keys(latestCoinInfo).length > 0
    ? (chain(data)
        .map(({ contract_address, total_cost, start_timestamp }) => {
          if (!latestCoinInfo[contract_address]) {
            return null;
          }
          const { price, symbol, quantity, decimals } = latestCoinInfo[contract_address];
          const balance = toBalance<BigNumber>(quantity, decimals);
          return {
            contract_address,
            price,
            symbol,
            balance,
            value: balance.times(price || 0),
            total_cost,
            start_timestamp
          };
        })
        .filter(v => !!v)
        .sortBy(item => -item!.value.toNumber())
        .value() as FusedAssetData[])
    : [];
};

const useAssetCost = (
  address: string | null
): {
  data: FusedAssetData[];
  overCount: number;
  loading: boolean;
} => {
  const [data, setAssetCost] = useState<FusedAssetData[]>([]);
  const [loading, setLoading] = useState(false);
  const latestCoinInfo = useLatestCoinInfo(address);

  useEffect(() => {
    if (!address) {
      return;
    }
    setLoading(true);
    requestFromFF<AssetCost[]>(FUNCTION.GET_ASSET_COST, address).then(res => {
      const fusedData = fuseData(res, latestCoinInfo);
      setAssetCost(fusedData);
      setLoading(false);
    });
  }, [address, latestCoinInfo]);

  return { data: data.slice(0, 20), overCount: data.length - 20, loading };
};

export default useAssetCost;
