import { memo, useContext } from 'react';
import {
  Card,
  CardFooter,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  TableColumnDefinition,
  Text,
  Tooltip,
  makeStyles,
  shorthands
} from '@fluentui/react-components';
import Loading from '@geist-ui/core/esm/loading';
import BigNumber from 'bignumber.js';
import { ChevronRight20Regular } from '@fluentui/react-icons';

import { AddressContext } from './Provider/AddressProvider';
import ColorText from './style-component/ColorText';

import Grid from '@/component/style-component/Grid';
import MarketText from '@/component/style-component/MarketText';
import useAssetCost, { FusedAssetData } from '@/hook/useAssetCost';
import { formatCurrency, formatPercent, formatQuantity } from '@/utils';
import { themeTokens } from '@/theme';

const columns: TableColumnDefinition<FusedAssetData & { change: string }>[] = [
  {
    columnId: 'symbol',
    compare: (a, b) => a.symbol.localeCompare(b.symbol),
    renderHeaderCell: () => 'Symbol',
    renderCell: item => item.symbol
  },
  {
    columnId: 'balance',
    compare: (a, b) => Number(a.value?.gt(b.value)),
    renderHeaderCell: () => 'Amount',
    renderCell: item => formatQuantity(item.balance)
  },
  {
    columnId: 'price',
    compare: (a, b) => a.price.localeCompare(b.price),
    renderHeaderCell: () => 'Price',
    renderCell: item => formatCurrency(BigNumber(item.price))
  },
  {
    columnId: 'value',
    compare: (a, b) => Number(a.value?.gt(b.value)),
    renderHeaderCell: () => 'Value',
    renderCell: item => formatCurrency(item.value)
  },
  {
    columnId: 'total_cost',
    compare: (a, b) => a.total_cost.localeCompare(b.total_cost),
    renderHeaderCell: () => 'Total Cost',
    renderCell: item => (
      <Grid direction="column">
        <Text>{formatCurrency(BigNumber(item.total_cost))}</Text>
        <ColorText size={200} type="tertiary">
          {formatCurrency(BigNumber(item.total_cost).div(item.balance))}
        </ColorText>
      </Grid>
    )
  },
  {
    columnId: 'change',
    compare: (a, b) => a.change.localeCompare(b.change),
    renderHeaderCell: () => 'Change',
    renderCell: item => {
      const change = BigNumber(item.value || 0).minus(item.total_cost);
      return (
        <Grid direction="column" className="flex">
          <MarketText>{formatCurrency(change, true)}</MarketText>
          <MarketText size={200} className="mt-0.5">
            {formatPercent(
              BigNumber(item.total_cost).gt(0) ? change.div(BigNumber(item.total_cost).abs()) : 0,
              true
            )}
          </MarketText>
        </Grid>
      );
    }
  },
  {
    columnId: 'more',
    compare: () => 0,
    renderHeaderCell: () => 'More',
    renderCell: () => (
      <Tooltip content="Trade details. Coming soon..." relationship="label">
        <ChevronRight20Regular className="cursor-pointer" />
      </Tooltip>
    )
  }
];

const useOverrides = makeStyles({
  dataGridHeaderRow: {
    backgroundColor: themeTokens.colorSubtleBackgroundSelected,
    color: themeTokens.colorNeutralForeground3,
    ...shorthands.border('none'),
    ...shorthands.borderRadius(themeTokens.borderRadiusSmall),
    // HACK: set the last column to fixed width
    '> .fui-DataGridHeaderCell:last-child': {
      flexGrow: 0,
      flexBasis: '56px'
    }
  },
  dataGridBodyRow: {
    '> .fui-DataGridCell:last-child': {
      flexGrow: 0,
      flexBasis: '56px'
    }
  }
});

const AssetList = () => {
  const { address } = useContext(AddressContext);
  const { data, overCount, loading } = useAssetCost(address);

  const overrides = useOverrides();

  return (
    <Card style={{ padding: '20px' }} appearance="filled-alternative">
      <DataGrid items={data} columns={columns}>
        <DataGridHeader>
          <DataGridRow className={overrides.dataGridHeaderRow}>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        {loading ? (
          <div className="mt-4">
            <Loading />
          </div>
        ) : (
          <DataGridBody<FusedAssetData>>
            {({ item }) => (
              <DataGridRow<FusedAssetData>
                key={item.contract_address}
                className={overrides.dataGridBodyRow}
              >
                {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
              </DataGridRow>
            )}
          </DataGridBody>
        )}
      </DataGrid>
      {!loading && overCount > 0 && (
        <CardFooter>
          <ColorText type="secondary">{overCount} assets are hidden</ColorText>
        </CardFooter>
      )}
    </Card>
  );
};

export default memo(AssetList);
