import { Divider, Image, Link } from '@fluentui/react-components';

import ColorText from './style-component/ColorText';

import Grid from '@/component/style-component/Grid';

const Footer = () => {
  return (
    <div>
      <Divider appearance="subtle" />
      <Grid height="48px" alignItems="center" justify="flex-end" className="px-16">
        <Grid alignItems="center" gap={8}>
          <ColorText type="tertiary">Find us on</ColorText>
          <Link
            className="flex item-center"
            appearance="subtle"
            href="https://discord.com/invite/5wK83mD9wk"
          >
            <Image
              src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6ca814282eca7172c6_icon_clyde_white_RGB.svg"
              alt="discord"
              width="20px"
              height="20px"
              className="opacity-50 hover:opacity-80"
            />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
