import React, { createContext, useCallback, useMemo, useState } from 'react';

type AddressContextType = {
  address: null | string;
  setAddress: (_address: string) => void;
};

export const AddressContext = createContext<AddressContextType>({
  address: null,
  setAddress: () => {}
});

const AddressProvider = ({ children }: { children: React.ReactNode }) => {
  const [address, setAddress] = useState<null | string>(localStorage.getItem('address'));

  const saveAddress = useCallback((value: string) => {
    localStorage.setItem('address', value);
    setAddress(value);
  }, []);

  const value = useMemo(() => ({ address, setAddress: saveAddress }), [address, saveAddress]);

  return <AddressContext.Provider value={value}>{children}</AddressContext.Provider>;
};

export default AddressProvider;
