export enum FUNCTION {
  GET_CASH_FLOW = 'getCashFlow',
  GET_ASSET_COST = 'getAssetCost',
  GET_LATEST_COIN_INFO = 'getLatestCoinInfo',
  GET_LATEST_ASSET_COST = 'getLatestAssetCost',
  GET_COIN_DETAIL = 'getCoinTxDetail',
  GET_LATEST_COIN_TX_DETAIL = 'getLatestCoinTxDetail',
  GET_HIST_COIN_VALUES = 'getHistCoinValues',
  GET_LATEST_HIST_COIN_VALUES = 'getLatestHistCoinValues',
  GET_MODIFIED_DIETZ_BY_WEEK = 'getModifiedDietzByWeek',
  GET_LATEST_MODIFIED_DIETZ_BY_WEEK = 'getLatestModifiedDietzByWeek',
  GET_RATE = 'getRate',
  GET_TXS = 'getTxs',
  GET_GAS_INFO = 'getGasInfo',
  GET_MARKET_PRICE = 'getMarketPrice',
  GET_NFT_CHAIN = 'getNFTChain',
  GET_NFT_STATS = 'getNFTStats',
  GET_NFT_COST = 'getNFTCost',
  GET_COLLECTIONS_INFO = 'getCollectionsInfo',
  REMOVE_ADDRESS = 'removeAddress',
  GET_TX_STREAM = 'getTxStream',
  CHECK_ADDRESS = 'checkAddress',
  GET_USERINFO = 'getUserInfo',
  UPDATE_USERINFO = 'updateUserInfo'
}

export const PAGE_SIZE = 10;
