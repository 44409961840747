import { useEffect } from 'react';
import { Divider, FluentProvider } from '@fluentui/react-components';
import { GeistProvider } from '@geist-ui/core';

import { theme } from './theme';
import AddressProvider from './component/Provider/AddressProvider';

import Overview from '@/page/Overview';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import Side from '@/component/Side';

import './App.css';
import './tailwind.css';

const App = () => {
  useEffect(() => {
    document.body.style.backgroundColor = theme.colorNeutralBackground1;
  }, []);

  return (
    <FluentProvider theme={theme}>
      <GeistProvider themeType="dark">
        <AddressProvider>
          <div className="flex min-h-screen">
            <Side />
            <div className="flex-grow">
              <Header />
              <Divider appearance="subtle" />
              <Overview />
              <Footer />
            </div>
          </div>
        </AddressProvider>
      </GeistProvider>
    </FluentProvider>
  );
};

export default App;
